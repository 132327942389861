import firebase from './firebase';

const twitterProvider = new firebase.auth.TwitterAuthProvider();
firebase.auth().languageCode = 'ja';

export const twitterLogin = async () => {
  try {
    firebase.auth().signInWithRedirect(twitterProvider);
    window.localStorage.setItem('needAccessKey', true);
    window.localStorage.setItem('typeLogin', 'twitter');
    if (window.localStorage.getItem('showTutorial')) {
      window.localStorage.setItem('showTabBarBottom', true);
    }
  } catch (error) {
  }
}

export const logout = async () => {
  try {
    window.localStorage.removeItem('twitterId');
    window.localStorage.removeItem('user_id');
    window.localStorage.removeItem('eventId');
    window.localStorage.removeItem('register');
    window.localStorage.removeItem('needAccessKey');
    window.localStorage.removeItem('jwt_id');
    window.localStorage.removeItem('playerEventId');
    window.localStorage.removeItem('showTabBarBottom');
    window.localStorage.removeItem('playsheetID');
    window.localStorage.removeItem('typeLogin');
    // 認証切れ対策（2022-10-16）
    // await firebase.auth().signOut();
    // await serverApi({ url: 'players/logout', method: 'post' });
    // store.dispatch(actionLogout());

  } catch (error) {
  }
}
